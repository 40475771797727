var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"max-width":"400px"}},[_c('defaultFieldTypes',_vm._g({attrs:{"templateContent":_vm.result,"field":_vm.field,"fieldAttributes":{
      ..._vm.fieldAttributes,
      fieldAttrInput: _vm.fieldAttrInput,
      type: _vm.type,
      value: _vm.localValue,
      name: _vm.name,
    },"checkReadOnly":_vm.checkReadOnly,"value":_vm.localValue}},_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }