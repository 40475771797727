<template>
  <div style="max-width: 400px">
    <defaultFieldTypes
      :templateContent="result"
      :field="field"
      :fieldAttributes="{
        ...fieldAttributes,
        fieldAttrInput: fieldAttrInput,
        type: type,
        value: localValue,
        name: name,
      }"
      :checkReadOnly="checkReadOnly"
      v-on="$listeners"
      :value="localValue"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  props: ["fieldAttributes", "field", "result", "checkReadOnly", "value","valueLine"],
  components: { defaultFieldTypes },
  computed: {
    fieldAttrInput() {
      let fieldAttrInput = { max: 60, min: 1, class: "number" };
      if (this.valueLine.timeVol == "volume") {
        fieldAttrInput = {};
      }
      return fieldAttrInput;
    },
    type() {
      let type = "number";
      if (this.valueLine.timeVol == "volume") {
        type = "text";
      }
      return type;
    },
    name() {
      let name = "Seconds";
      if (this.valueLine.timeVol == "volume") {
        name = "Volume";
      }
      return name;
    },
    localValue() {
      let value = this.value;
      if (this.valueLine.timeVol == "volume" && !isNaN(value)) {
        value = "10M";
        this.$emit("input", value);
      }
      if (this.valueLine.timeVol == "time" && isNaN(value)) {
        value = "10";
        this.$emit("input", value);
      }
      return value;
    },

    // if 'timeVol=time' then: interger, min: 1 max: 60, default 10
    // if 'timeVol=volume' then text, default 10M

    //   name:{}
  },
};
</script>